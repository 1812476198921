body {
  margin: 0;
  padding: 0;
  position: relative;
  overflow-x: hidden;
  padding-bottom: 60px;
  @media (max-width: 549px) {
    overflow-y: scroll;
  }
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #434343;
  a {
    color: #434343;
  }
}

header {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 60px;
  z-index: 3;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .logo {
    display: inline-block;
    padding: 20px;
    width: 30px;
    height: 30px;
    transition: 0.1s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
  }

  .npm,
  .github,
  .email {
    float: right;
    padding: 20px 20px 0 0;
    width: 30px;
    height: 30px;

  }

  .email {
    svg {
      width: 30px;
    height: 30px;
      g {
        fill: #777777;
      }
      transition: 0.1s ease-in-out;
      &:hover {
        transform: scale(1.1);
        g {
          fill: red;
        }
      }
    }
  }

  .github {
    svg {
      width: 30px;
      height: 30px;
      .cls-1 {
        fill: #777777;
      }
      transition: 0.1s ease-in-out;
      &:hover {
        transform: scale(1.1);
        .cls-1 {
          fill: #434343;
        }
      }
    }
  }

  .npm {
    svg {
      width: 30px;
    height: 30px;
      #Selection2 {
        fill: #777777;
      }
      transition: 0.1s ease-in-out;
      &:hover {
        transform: scale(1.1);
        #Selection2 {
          fill: red;
        }
      }
    }
  }
}

.svg-wrapper.loaded {
  animation: load ease-in-out 0.4s;
  animation-fill-mode: forwards;
}

.svg-wrapper {
  opacity: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  min-height: calc(100vh - 60px);
  flex-direction: column;
  .code-text {
    text-align: center;
  }
  h1 {
    margin: 16px;
  }
  pre {
    margin: 0px;
  }
  code {
    background-color: rgb(240, 240, 240);
    border-radius: 8px;
    padding: 10px;
    width: calc(100vw - 60px);
    max-width: 420px;
    font-size: 14px;
    display: block;
    white-space: pre-wrap;
    line-height: 1.4;
    @media screen and (min-width: 550px) {
      font-size: 18px;
      max-width: 520px;
    }
  }
  div.svg-logo{
    height: 100%;
    width: 100%;
    max-width: 350px;
    svg {
      height: 100%;
      width: 100%;
      max-height:350px;
      max-width: 350px;
      max-height: 450px;
      max-width: 450px;
      .svgbar {
        position: absolute;
        fill: #ffb13b;
        animation: colorchange 15s ease-in-out infinite;
      }
    }



  }
 
}

footer {
  position: absolute;
  right: 20px;
  left: 20px;
  bottom: 20px;
  text-align: center;
  font-size: 14px;
}

@keyframes load {
  0% {
    transform: scale(0.7);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes colorchange {
  0% {
    fill: #ffb13b;
    transform: rotate(0deg);
  }

  25% {
    fill: green;
  }

  50% {
    fill: blue;
    transform: rotate(360deg);
  }

  75% {
    fill: red;
  }

  100% {
    fill: #ffb13b;
    transform: rotate(0deg);
  }
}